<!--  -->
<template>
  <div class='xuankao round bj-col-white pad'>
    <div class="heard">
      <div class="year">
        <el-select v-model="year" placeholder="请选择" @change="changeYear" class="picker">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <span>选择年份</span>
      </div>
    </div>
    <div class="item">
      <h3>手机考试端</h3>
      <img src="../assets/img/code/study-h5.png" alt="">
      <span>扫码关注公众号，进入“立即学习”立即“考试”</span>
      <div class="hint">电脑需安装或自带摄像头，如没有请扫描进入公众号使用手机考试。</div>
    </div>
    <div class="item">
      <h3>电脑考试</h3>
      <img src="../assets/img/kaoshihint.png" alt="">
      <el-button round plain style="width: 250px" size="medium" type="success" @click="getRoot()" >{{year}}年执业药师正式考试</el-button>
    </div>

    <el-dialog
      :visible.sync="hint"
      width="30%"
      top="12vh"
      center>
      <div class="hint">
        <img src="../assets/img/tishi.png" alt="">
        <p>
          <!-- 1、按省药监局“执业药师管理系统”要求：考试过程中将人脸识别和随机抓拍，请考生注意着装，端正坐姿，不得有其他人出现在摄像范围内，如不规范，考试无效。
          <br/> -->
          1、考试成绩60分及格，考到成绩及格为止。
        </p>
      </div>
      <span slot="footer">
        <el-button round type="success" class="self-button--success" size="medium" style="width: 160px" @click="handleSkip()">我知道了</el-button>
      </span>
    </el-dialog>

    <video-view v-model="show" type="kshy"></video-view>
  </div>
</template>

<script>
import Api from '../api/study'
import VideoView from './components/video/index.vue'

export default {
  components: {
    VideoView
  },
  data() {
    return {
      hint: false,
      show: false,
      year: '',
      options: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    this.options = userInfo.years.map(item => {
      return {
        label: item,
        value: item
      }
    })
    this.year = localStorage.getItem("year")
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getRoot () {
      Api.getRoot().then(res => {
        if (res.data.code == 1) {
          this.hint = true
        } else {
          this.$message.info({
            message: res.data.msg || "您的学习还未完成，请继续学习",
            customClass: 'toast'
          })
        }
      })
    },
    handleSkip () {
      this.hint = false
      this.show = true
      // this.$router.push({name: 'Kaoshi', query: {id: 1}})
    },
    changeYear () {
      localStorage.setItem("year", this.year)
      this.setUserInfo()
    }
  },
}
</script>

<style lang='less' scoped>
.xuankao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &::after {
    content: '';
    width: 1px;
    height: 70%;
    background-color: #D8D8D8;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .heard {
    position: absolute;
    top: 20px;
    right: 20px;
    .year {
      display: flex;
      align-items: center;
      > span {
        margin-left: 15px;
      }
    }
  }
  > .item {
    margin: 100px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 150px;
      margin: 20px 0;
    }
    .hint {
      color: red;
    }
  }

  .hint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 80px;
    }
    p {
      margin-top: 30px;
      color: #000000;
      padding: 0 30px;
    }
  }

}
</style>