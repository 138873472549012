import { render, staticRenderFns } from "./xuankao.vue?vue&type=template&id=a85deb28&scoped=true"
import script from "./xuankao.vue?vue&type=script&lang=js"
export * from "./xuankao.vue?vue&type=script&lang=js"
import style0 from "./xuankao.vue?vue&type=style&index=0&id=a85deb28&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a85deb28",
  null
  
)

export default component.exports